<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="matches" :title="title" :subtitle="subtitle">
      <section>
        <!-- Select da rodada -->
        <div class="align-title-jg">
          <restrict-access slug="jogos.filter">
            <div class="columns is-multiline is-mobile" v-if="$mq == 'sm'">
              <div class="column is-full" style="padding-bottom: 0">
                <div
                  class="atl-legend"
                  @click="showFilters = !showFilters"
                  :style="{ 'margin-bottom': !showFilters ? '20px' : '0' }"
                >
                  <h2>
                    Escolha uma Rodada
                    <b-icon
                      pack="fas"
                      :icon="showFilters ? 'chevron-up' : 'chevron-down'"
                      size="is-small"
                    ></b-icon>
                  </h2>
                </div>
                <div class="rodadas" v-if="showFilters">
                  <div
                    v-for="(el, index) of rodadas"
                    :key="index"
                    :class="{
                      rodada: true,
                      'rodada-selected': el == selectRodada,
                    }"
                    @click="updateJogos(el)"
                  >
                    {{ el }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-mobile" v-else>
              <div class="column is-full" style="padding-bottom: 0">
                <div class="rodadas">
                  <p class="rodada-title">Escolha uma Rodada</p>
                  <div
                    v-for="(el, index) of rodadas"
                    :key="index"
                    :class="{
                      rodada: true,
                      'rodada-selected': el == selectRodada,
                    }"
                    @click="updateJogos(el)"
                  >
                    {{ el }}
                  </div>
                </div>
              </div>
            </div>
          </restrict-access>
        </div>
        <div v-if="jogos.length > 0" class="columns is-multiline">
          <div
            v-for="(match, idJogo) of jogos"
            class="column is-one-third"
            :key="idJogo"
          >
            <div class="jg-content">
              <div class="columns is-multiline">
                <!-- title and header -->
                <div
                  class="column is-full header-title-jg"
                  style="margin-bottom: 3px; padding-bottom: 0"
                >
                  <b-button
                    class="align-bt-jg"
                    type="is-primary"
                    :icon-right="
                      match.showEstatistica ? 'plus' : 'chevron-left'
                    "
                    pack="fas"
                    size="is-small"
                    v-on:click="toggleInfo(idJogo)"
                  />
                  <div
                    class="hd-title-jg in-progress-color"
                    :class="{
                      'ending-color': match.status == 'Jogo Finalizado',
                      'coming-soon-color':
                        match.status == 'Placar mais provável',
                      'in-progress-color': match.status == 'Jogo em andamento',
                    }"
                  >
                    {{ match.status }}
                  </div>
                </div>

                <!-- condicoes climaticas -->
                <div
                  class="column is-full"
                  style="margin-bottom: 3px; padding-bottom: 0"
                >
                  <div class="level is-mobile" style="margin-bottom: 5px;">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="fit-content-jg">Estádio</div>
                      </div>
                      <div class="level-item">
                        <p class="text-jg">{{ match.estadio }}</p>
                      </div>
                    </div>
                    <div class="level-right">
                      <div v-if="match.clima">
                        <span class="text-jg" style="padding-right: 7px;">{{
                          match.data
                        }}</span>
                        <b-icon
                          pack="fas"
                          :icon="formatterWeather(match.clima)"
                          size="is-small"
                        ></b-icon>
                      </div>
                      <div v-else>
                        <span class="text-jg">{{ match.data }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="level is-mobile" style="margin-bottom: 5px;">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="fit-content-jg">Local</div>
                      </div>
                      <div class="level-item">
                        <p class="text-jg">
                          {{ `${match.cidade} - ${match.estado}` }}
                        </p>
                      </div>
                    </div>
                    <div class="level-right">
                      <div
                        class="fit-content-wheater max-wheater"
                        v-if="match.clima"
                      >
                        <span style="padding-left: 5px;">Máx.</span>
                        <div
                          class="fit-content-wheater-2 max-wheater float-rt "
                        >
                          {{ match.temp_max }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="level is-mobile" style="margin-bottom: 5px;">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="fit-content-jg">Árbitro</div>
                      </div>
                      <div class="level-item">
                        <p class="text-jg" v-if="match.arbitro">
                          {{
                            isMobile
                              ? formatterChar(match.arbitro)
                              : match.arbitro
                          }}
                        </p>
                        <p class="text-jg" v-else>-</p>
                      </div>
                    </div>
                    <div class="level-right">
                      <div
                        class="fit-content-wheater min-wheater"
                        v-if="match.clima"
                      >
                        <span style="padding-left: 5px;">Min.</span>
                        <div
                          class="fit-content-wheater-2 min-wheater float-rt "
                        >
                          {{ match.temp_min }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- poison -->
                <div class="column is-full">
                  <div class="fit-content-3">{{ match.status }}</div>

                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="round-team-atl">
                          <img
                            :src="match.escudoClubeCasa"
                            :alt="match.nomeClubeCasa"
                            width="30"
                          />
                        </div>
                      </div>
                      <div class="level-item">
                        <p class="nome-time-jg">{{ match.siglaClubeCasa }}</p>
                      </div>
                    </div>
                    <div class="placar-jg">{{ match.placar }}</div>
                    <div class="level-right">
                      <div class="level-item">
                        <p class="nome-time-jg">{{ match.siglaClubeFora }}</p>
                      </div>
                      <div class="level-item">
                        <div class="round-team-atl">
                          <img
                            :src="match.escudoClubeFora"
                            :alt="match.nomeClubeFora"
                            width="30"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="match.showEstatistica">
                    <div v-if="match.provavel">
                      <div
                        class="level is-mobile"
                        v-for="(scout, idScout) in match.scoutsProvavel"
                        :key="idScout"
                        style="margin-bottom: 0.9rem;"
                      >
                        <div
                          class="fit-content-jg"
                          style="font-size: 0.58rem; width: 90px; padding: 5px;"
                        >
                          {{ scout.titulo }}
                        </div>
                        <div class="progress-content">
                          <div
                            class="progress-bar-jg-1"
                            :style="{
                              width:
                                scout.percentCasa == 0
                                  ? '7%'
                                  : scout.percentCasa.toString() + '%',
                            }"
                            v-html="scout.descCasa"
                          ></div>
                          <div
                            class="progress-bar-jg-2"
                            :style="{
                              width:
                                scout.percentFora == 0
                                  ? '7%'
                                  : scout.percentFora.toString() + '%',
                            }"
                            v-html="scout.descFora"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="level is-mobile"
                        v-for="(scout, idScout) in match.scoutsReal"
                        :key="idScout"
                        style="margin-bottom: 0.9rem;"
                      >
                        <div
                          class="fit-content-jg"
                          style="font-size: 0.58rem; width: 90px; padding: 5px;"
                        >
                          {{ scout.titulo }}
                        </div>
                        <div class="progress-content scout-content">
                          <div
                            class="progress-bar-jg-1 scout-bar"
                            :style="{
                              width:
                                scout.percentCasa == 0
                                  ? '7%'
                                  : scout.percentCasa.toString() + '%',
                            }"
                            v-html="scout.descCasa"
                          ></div>
                          <div
                            class="progress-bar-jg-2 scout-bar"
                            :style="{
                              width:
                                scout.percentFora == 0
                                  ? '7%'
                                  : scout.percentFora.toString() + '%',
                            }"
                            v-html="scout.descFora"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="match.provavel">
                      <template v-if="$mq == 'sm'">
                        <b-tabs type="is-boxed" size="is-small">
                          <b-tab-item :label="match.nomeClubeCasa">
                            <div
                              v-for="(atleta, idAtleta) in match
                                .atletasProvaveis.casa"
                              :key="idAtleta"
                              class="atletas-casa"
                              v-bind:class="{ isClienteAtleta: atleta.cliente }"
                            >
                              <div class="atletas-pontos">
                                <i
                                  v-if="atleta.statusIcone"
                                  :class="
                                    atleta.statusIcone +
                                      ' status-' +
                                      atleta.statusColor
                                  "
                                ></i>
                                {{ atleta.posicao }} {{ atleta.apelido }}
                              </div>
                            </div>
                          </b-tab-item>
                          <b-tab-item :label="match.nomeClubeFora">
                            <div
                              v-for="(atleta, idAtleta) in match
                                .atletasProvaveis.fora"
                              :key="idAtleta"
                              class="atletas-fora"
                              v-bind:class="{ isClienteAtleta: atleta.cliente }"
                            >
                              <div class="atletas-pontos">
                                <i
                                  v-if="atleta.statusIcone"
                                  :class="
                                    atleta.statusIcone +
                                      ' status-' +
                                      atleta.statusColor
                                  "
                                ></i>
                                {{ atleta.posicao }} {{ atleta.apelido }}
                              </div>
                            </div>
                          </b-tab-item>
                        </b-tabs>
                      </template>
                      <template v-if="$mq !== 'sm'">
                        <div class="columns">
                          <div
                            class="column is-half is-mobile atletas"
                            v-if="match.atletasProvaveis.casa.length > 0"
                          >
                            <div
                              v-for="(atleta, idAtleta) in match
                                .atletasProvaveis.casa"
                              :key="idAtleta"
                              class="atletas-casa"
                              v-bind:class="{ isClienteAtleta: atleta.cliente }"
                            >
                              <div class="atletas-pontos">
                                <i
                                  v-if="atleta.statusIcone"
                                  :class="
                                    atleta.statusIcone +
                                      ' status-' +
                                      atleta.statusColor
                                  "
                                ></i>
                                {{ atleta.posicao }} {{ atleta.apelido }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="column is-half is-mobile atletas"
                            v-if="match.atletasProvaveis.fora.length > 0"
                          >
                            <div
                              v-for="(atleta, idAtleta) in match
                                .atletasProvaveis.fora"
                              :key="idAtleta"
                              class="atletas-fora"
                              v-bind:class="{ isClienteAtleta: atleta.cliente }"
                            >
                              <div class="atletas-pontos">
                                <i
                                  v-if="atleta.statusIcone"
                                  :class="
                                    atleta.statusIcone +
                                      ' status-' +
                                      atleta.statusColor
                                  "
                                ></i>
                                {{ atleta.posicao }} {{ atleta.apelido }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div v-else>
                      <template v-if="$mq == 'sm'">
                        <b-tabs type="is-boxed" size="is-small">
                          <b-tab-item :label="match.nomeClubeCasa">
                            <div
                              v-for="(scout, idScout) in match.scoutsAtletas
                                .casa"
                              :key="idScout"
                              class="atletas-casa"
                              v-bind:class="{ isClienteAtleta: scout.cliente }"
                            >
                              <div class="atletas-pontos">
                                {{ scout.posicao }} {{ scout.apelido }} ({{
                                  scout.pontos
                                }})
                              </div>
                              <div
                                class="atletas-scouts"
                                v-if="
                                  scout.scoutsPos !== '' ||
                                    scout.scoutsNeg !== ''
                                "
                              >
                                <div
                                  class="atletas-scouts-positivos"
                                  v-if="scout.scoutsPos !== ''"
                                  v-html="scout.scoutsPos"
                                ></div>
                                <div
                                  class="atletas-scouts-negativos"
                                  v-if="scout.scoutsNeg !== ''"
                                  v-html="scout.scoutsNeg"
                                ></div>
                              </div>
                            </div>
                          </b-tab-item>
                          <b-tab-item :label="match.nomeClubeFora">
                            <div
                              v-for="(scout, idScout) in match.scoutsAtletas
                                .fora"
                              :key="idScout"
                              class="atletas-fora"
                              v-bind:class="{ isClienteAtleta: scout.cliente }"
                            >
                              <div class="atletas-pontos">
                                {{ scout.posicao }} {{ scout.apelido }} ({{
                                  scout.pontos
                                }})
                              </div>
                              <div
                                class="atletas-scouts"
                                v-if="
                                  scout.scoutsPos !== '' ||
                                    scout.scoutsNeg !== ''
                                "
                              >
                                <div
                                  class="atletas-scouts-positivos"
                                  v-if="scout.scoutsPos !== ''"
                                  v-html="scout.scoutsPos"
                                ></div>
                                <div
                                  class="atletas-scouts-negativos"
                                  v-if="scout.scoutsNeg !== ''"
                                  v-html="scout.scoutsNeg"
                                ></div>
                              </div>
                            </div>
                          </b-tab-item>
                        </b-tabs>
                      </template>
                      <template v-if="$mq !== 'sm'">
                        <div class="columns">
                          <div
                            class="column is-half is-mobile atletas"
                            v-if="match.scoutsAtletas.casa.length > 0"
                          >
                            <div
                              v-for="(scout, idScout) in match.scoutsAtletas
                                .casa"
                              :key="idScout"
                              class="atletas-casa"
                              v-bind:class="{ isClienteAtleta: scout.cliente }"
                            >
                              <div class="atletas-pontos">
                                {{ scout.posicao }} {{ scout.apelido }} ({{
                                  scout.pontos
                                }})
                              </div>
                              <div
                                class="atletas-scouts"
                                v-if="
                                  scout.scoutsPos !== '' ||
                                    scout.scoutsNeg !== ''
                                "
                              >
                                <div
                                  class="atletas-scouts-positivos"
                                  v-if="scout.scoutsPos !== ''"
                                  v-html="scout.scoutsPos"
                                ></div>
                                <div
                                  class="atletas-scouts-negativos"
                                  v-if="scout.scoutsNeg !== ''"
                                  v-html="scout.scoutsNeg"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="column is-half is-mobile atletas"
                            v-if="match.scoutsAtletas.fora.length > 0"
                          >
                            <div
                              v-for="(scout, idScout) in match.scoutsAtletas
                                .fora"
                              :key="idScout"
                              class="atletas-fora"
                              v-bind:class="{ isClienteAtleta: scout.cliente }"
                            >
                              <div class="atletas-pontos">
                                {{ scout.posicao }} {{ scout.apelido }} ({{
                                  scout.pontos
                                }})
                              </div>
                              <div
                                class="atletas-scouts"
                                v-if="
                                  scout.scoutsPos !== '' ||
                                    scout.scoutsNeg !== ''
                                "
                              >
                                <div
                                  class="atletas-scouts-positivos"
                                  v-if="scout.scoutsPos !== ''"
                                  v-html="scout.scoutsPos"
                                ></div>
                                <div
                                  class="atletas-scouts-negativos"
                                  v-if="scout.scoutsNeg !== ''"
                                  v-html="scout.scoutsNeg"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="columns">
          <div class="column">
            <div class="section">
              <div
                class="content has-text-grey has-text-centered"
                style="font-size: 1rem;"
              >
                <b-loading active></b-loading>
                <!-- <p>
                  <b-icon pack="fas" icon="spinner" size="is-medium"> </b-icon>
                </p>
                <p>
                  As informações estão sendo carregadas, aguarde um instante.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </BasePage>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import vSelect from "vue-select";
import Moment from "moment";
import restrictAccess from "@/components/restrictAccess/restrictAccess";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

export default {
  name: "Matches",
  mixins: [championshipMixins],
  components: {
    BaseLayout,
    BasePage,
    "v-select": vSelect,
    "restrict-access": restrictAccess,
  },
  data() {
    return {
      count: 0,
      jogos: [],
      rodada: "",
      selectRodada: "",
      rodadas: [],
      partidas: [],
      interval: null,
      showFilters: false,
    };
  },
  async created() {
    await this.loadChampionships();
    this.setDefaultToBrazilChampionship();

    this.$http
      .get(url + "/jogos/rodadas")
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        // let loader = this.$loading.show()

        if (response) {
          this.rodadas = response.data.data;

          if (this.rodadas) {
            this.selectRodada = this.rodadas[0];
            this.rodada = this.selectRodada;
          }
          this.updateJogos(this.selectRodada);

          let scope = this;
          this.interval = setInterval(function() {
            scope.updateJogos();
          }, 10000);
        }

        // loader.hide()
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    infoJogos() {
      return this.jogos;
    },
    title() {
      return `Jogos da Rodada ${this.selectRodada}`;
    },
    subtitle() {
      return "Lista de jogos com informações importantes e estatísticas sobre o confronto";
    },
  },
  methods: {
    updateJogos(idRodada) {
      if (idRodada) {
        this.selectRodada = idRodada;
      }

      if (this.selectRodada !== this.rodada) {
        this.rodada = this.selectRodada;
        this.partidas = [];
        this.jogos = [];
      }

      this.$http
        .post(url + "/jogos/partidas", {
          idTime: "",
          idRodada: this.rodada,
        })
        .catch((err) => {
          console.log(err);
        })
        .then((response) => {
          if (response) {
            let partidas = response.data.data;

            for (const partida of partidas) {
              let dataHora = Moment(new Date(partida.data));
              let dataPartida = dataHora.format("DD/MM/YYYY - HH:mm ");

              let status =
                partida.status === "encerrado"
                  ? "Jogo Finalizado"
                  : partida.status === "aovivo"
                  ? "Jogo em andamento"
                  : "Placar mais provável";
              let aovivo = partida.status === "aovivo";
              let provavel = partida.status === "provavel";

              let descCasa = "";
              let descFora = "";
              let percentCasa = 0;
              let percentFora = 0;

              //Scouts Prováveis da Partida ainda não iniciada
              let scoutsProvavel = [];

              for (const scout of partida.scouts_provavel) {
                let scoutCasa = scout.casa ? scout.casa : 0;
                let scoutFora = scout.fora ? scout.fora : 0;

                if (scout.sigla === "POSICAO") {
                  descCasa = Math.round(scoutCasa) + "º mandante";
                  descFora = Math.round(scoutFora) + "º visitante";
                  percentCasa = Math.round((scoutFora / scout.total) * 100);
                  percentFora = Math.round((scoutCasa / scout.total) * 100);
                } else if (scout.sigla === "MEDIA") {
                  descCasa = scoutCasa.toFixed(2) + " ptos";
                  descFora = scoutFora.toFixed(2) + " ptos";
                  percentCasa = Math.round((scoutCasa / scout.total) * 100);
                  percentFora = Math.round((scoutFora / scout.total) * 100);
                  if (scoutFora === 16.04) {
                    // console.log("casa: "+scoutCasa)
                    // console.log("fora: "+scoutFora)
                    // console.log("total: "+scout.total)
                    // console.log("margem: "+ajusteMargem)
                  }
                } else {
                  descCasa = Math.round(scoutCasa * 100) + "%";
                  descFora = Math.round(scoutFora * 100) + "%";
                  percentCasa = Math.round((scoutCasa / scout.total) * 100);
                  percentFora = Math.round((scoutFora / scout.total) * 100);
                }

                scoutsProvavel.push({
                  titulo: scout.nome,
                  descCasa: percentCasa > 0 ? descCasa : "",
                  descFora: percentFora > 0 ? descFora : "",
                  percentCasa: percentCasa + (percentCasa + 10 <= 100 ? 10 : 0),
                  percentFora: percentFora + (percentFora + 10 <= 100 ? 10 : 0),
                });
              }

              //Scouts Reais da Partida ao vivo
              let scoutsReal = [];
              let scoutMax =
                partida.scouts_real.maximo > 0 ? partida.scouts_real.maximo : 1;

              for (const scout of partida.scouts_real.scouts) {
                percentCasa = Math.round((scout.casa / scoutMax) * 100);
                percentFora = Math.round((scout.fora / scoutMax) * 100);
                scoutsReal.push({
                  titulo: scout.nome,
                  descCasa: percentCasa > 0 ? scout.casa : "",
                  descFora: percentFora > 0 ? scout.fora : "",
                  percentCasa: percentCasa + (percentCasa + 10 <= 100 ? 10 : 0),
                  percentFora: percentFora + (percentFora + 10 <= 100 ? 10 : 0),
                  scoutMax: scoutMax,
                });
              }

              let index = this.partidas.indexOf(partida.codigo);
              if (index === -1) {
                this.partidas.push(partida.codigo);
                this.jogos.push({
                  codigo: partida.codigo,
                  data: dataPartida,
                  estadio: partida.estadio,
                  cidade: partida.cidade,
                  estado: partida.estado,
                  arbitro: partida.arbitro,
                  clima: partida.clima,
                  temp_min: partida.temp_min,
                  temp_max: partida.temp_max,
                  aovivo: aovivo,
                  provavel: provavel,
                  status: status,
                  placar: partida.placar,
                  escudoClubeCasa: partida.clube_casa_escudo,
                  escudoClubeFora: partida.clube_fora_escudo,
                  siglaClubeCasa: partida.clube_casa_sigla,
                  siglaClubeFora: partida.clube_fora_sigla,
                  nomeClubeCasa: partida.clube_casa_nome,
                  nomeClubeFora: partida.clube_fora_nome,
                  atletasCliente: partida.atletas_cliente,
                  atletasProvaveis: partida.atletas_provaveis,
                  scoutsProvavel: scoutsProvavel,
                  scoutsReal: scoutsReal,
                  scoutsAtletas: partida.scouts_atletas,
                  showEstatistica: true,
                });
              } else {
                this.jogos[index].data = dataPartida;
                this.jogos[index].estadio = partida.estadio;
                this.jogos[index].cidade = partida.cidade;
                this.jogos[index].estado = partida.estado;
                this.jogos[index].arbitro = partida.arbitro;
                this.jogos[index].clima = partida.clima;
                this.jogos[index].temp_min = partida.temp_min;
                this.jogos[index].temp_max = partida.temp_max;
                this.jogos[index].aovivo = aovivo;
                this.jogos[index].provavel = provavel;
                this.jogos[index].status = status;
                this.jogos[index].placar = partida.placar;
                this.jogos[index].atletasCliente = partida.atletas_cliente;
                this.jogos[index].atletasProvaveis = partida.atletas_provaveis;
                this.jogos[index].scoutsProvavel = scoutsProvavel;
                this.jogos[index].scoutsReal = scoutsReal;
                this.jogos[index].scoutsAtletas = partida.scouts_atletas;
              }
            }
          }
        });
    },
    toggleInfo(id) {
      var lShow = this.jogos[id].showEstatistica === false ? true : false;
      return (this.jogos[id].showEstatistica = lShow);
    },
    colorMando(mando) {
      if (mando === "casa") return "#3669f3";
      if (mando === "fora") return "#db4646";
    },
    formatterWeather(text) {
      let a = text.toString();
      a = a.split("fa-")[1];
      return a;
    },
    formatterChar(text) {
      let a = text.toString();
      a = a.length > 50 ? a.substring(0, 50) + "..." : a;
      return a;
    },
  },
};
</script>

<style scoped>
.header-title-jg {
  position: relative;
}
.align-title-jg {
  display: flex;
}
.align-bt-jg {
  position: absolute;
  top: calc(50% + 5.499999px);
  left: 100%;
  z-index: 2;
  transform: translate(-140%, -50%);
}
.atl-legend {
  width: 100%;
  padding: 5px 10px;
}
.rodadas {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 0;
}
.rodada {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  width: 30px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
.rodada-title {
  font-size: 1.2rem;
}
.rodada-selected {
  color: #000;
  border: 1px solid rgba(0, 255, 204, 1);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  background-color: #00fffd;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
}
.jg-content {
  border-radius: 7px;
  background-color: #3f435e;
  padding: 10px 15px;
  color: white;
  width: 100%;
  height: auto;
}
.text-jg {
  font-size: 0.7rem;
}
.fit-content-jg {
  font-weight: bolder;
  text-transform: uppercase;
  width: 70px;
  padding: 2px 0;
  text-align: center;
  font-size: 0.65rem;
  border-radius: 20px;
  background-color: #34374b;
  /* background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 ); */
}
.fit-content-3 {
  font-weight: bolder;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
  padding: 7px 0;
  text-align: center;
  font-size: 0.7rem;
  border-radius: 20px;
  background-color: #232535;
  /* background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 ); */
}
.fit-content-wheater {
  border-radius: 20px;
  padding: 1px;
  width: 70px;
  color: white;
  font-size: 0.6rem;
}
.fit-content-wheater-2 {
  border-radius: 20px;
  padding: 1px 5px;
  color: white;
  text-align: center;
  font-size: 0.65rem;
}
.hd-title-jg {
  width: 100%;
  padding: 5px 10px;
  border-radius: 7px 7px 0 0;
  font-weight: bolder;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.round-team-atl {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.round-team-atl img {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -50%);
}
.nome-time-jg {
  font-size: 0.8rem;
  font-weight: bolder;
}
.placar-jg {
  font-size: 1.2rem;
  font-weight: bolder;
}
.progress-content {
  width: calc(100% - 100px);
  display: inline-flex;
}
.scout-content {
  display: inline-block !important;
}
.progress-bar-jg-1,
.progress-bar-jg-2 {
  text-align: center;
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
  overflow: hidden;
}
.progress-bar-jg-1 {
  border-radius: 20px 0 0 20px;
  background: rgba(52, 247, 215, 1);
  background: -moz-linear-gradient(
    left,
    rgba(52, 247, 215, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(52, 247, 215, 1)),
    color-stop(100%, rgba(39, 147, 230, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(52, 247, 215, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(52, 247, 215, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(52, 247, 215, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(52, 247, 215, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34f7d7', endColorstr='#2793e6', GradientType=1 );
}
.progress-bar-jg-2 {
  border-radius: 0 20px 20px 0;
  background: rgba(220, 54, 245, 1);
  background: -moz-linear-gradient(
    left,
    rgba(220, 54, 245, 1) 0%,
    rgba(128, 39, 230, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(220, 54, 245, 1)),
    color-stop(100%, rgba(128, 39, 230, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(220, 54, 245, 1) 0%,
    rgba(128, 39, 230, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(220, 54, 245, 1) 0%,
    rgba(128, 39, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(220, 54, 245, 1) 0%,
    rgba(128, 39, 230, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(220, 54, 245, 1) 0%,
    rgba(128, 39, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dc36f5', endColorstr='#8027e6', GradientType=1 );
}
.scout-bar {
  border-radius: 20px !important;
  height: 25px !important;
  line-height: 25px !important;
}

.max-wheater {
  background: rgba(250, 69, 37, 1);
  background: -moz-linear-gradient(
    left,
    rgba(250, 69, 37, 1) 0%,
    rgba(230, 144, 39, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(250, 69, 37, 1)),
    color-stop(100%, rgba(230, 144, 39, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(250, 69, 37, 1) 0%,
    rgba(230, 144, 39, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(250, 69, 37, 1) 0%,
    rgba(230, 144, 39, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(250, 69, 37, 1) 0%,
    rgba(230, 144, 39, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(250, 69, 37, 1) 0%,
    rgba(230, 144, 39, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa4525', endColorstr='#e69027', GradientType=1 );
}
.min-wheater {
  background: rgba(37, 115, 250, 1);
  background: -moz-linear-gradient(
    left,
    rgba(37, 115, 250, 1) 0%,
    rgba(39, 224, 230, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(37, 115, 250, 1)),
    color-stop(100%, rgba(39, 224, 230, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(37, 115, 250, 1) 0%,
    rgba(39, 224, 230, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(37, 115, 250, 1) 0%,
    rgba(39, 224, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(37, 115, 250, 1) 0%,
    rgba(39, 224, 230, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(37, 115, 250, 1) 0%,
    rgba(39, 224, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2573fa', endColorstr='#27e0e6', GradientType=1 );
}

.in-progress-color {
  color: #333;
  background: rgba(210, 255, 82, 1);
  background: -moz-linear-gradient(
    left,
    rgba(210, 255, 82, 1) 0%,
    rgba(145, 232, 66, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(210, 255, 82, 1)),
    color-stop(100%, rgba(145, 232, 66, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(210, 255, 82, 1) 0%,
    rgba(145, 232, 66, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(210, 255, 82, 1) 0%,
    rgba(145, 232, 66, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(210, 255, 82, 1) 0%,
    rgba(145, 232, 66, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(210, 255, 82, 1) 0%,
    rgba(145, 232, 66, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2ff52', endColorstr='#91e842', GradientType=1 );
}
.ending-color {
  color: white;
  background: rgba(255, 84, 84, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 84, 84, 1) 0%,
    rgba(230, 67, 67, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 84, 84, 1)),
    color-stop(100%, rgba(230, 67, 67, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 84, 84, 1) 0%,
    rgba(230, 67, 67, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 84, 84, 1) 0%,
    rgba(230, 67, 67, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 84, 84, 1) 0%,
    rgba(230, 67, 67, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 84, 84, 1) 0%,
    rgba(230, 67, 67, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5454', endColorstr='#e64343', GradientType=1 );
}
.coming-soon-color {
  color: white;
  background: rgba(90, 84, 255, 1);
  background: -moz-linear-gradient(
    left,
    rgba(90, 84, 255, 1) 0%,
    rgba(144, 69, 230, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(90, 84, 255, 1)),
    color-stop(100%, rgba(144, 69, 230, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(90, 84, 255, 1) 0%,
    rgba(144, 69, 230, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(90, 84, 255, 1) 0%,
    rgba(144, 69, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(90, 84, 255, 1) 0%,
    rgba(144, 69, 230, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(90, 84, 255, 1) 0%,
    rgba(144, 69, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a54ff', endColorstr='#9045e6', GradientType=1 );
}
.jg-content .atletas {
  display: inline-block;
  vertical-align: top;
  font-size: 0.7rem;
  /* width: 50%; */
}
.jg-content .atletas-casa {
  text-align: left;
  padding: 5px;
}
.jg-content .atletas-fora {
  text-align: right;
  padding: 5px;
}
.jg-content .atletas-scouts-positivos {
  display: inline-block;
  color: lightgreen;
  font-size: 0.7rem;
}
.jg-content .atletas-scouts-negativos {
  display: inline-block;
  color: lightcoral;
  font-size: 0.7rem;
}

@media (min-width: 342px) and (max-width: 1024px) {
  #jogos {
    height: 100%;
    width: 100%;
  }
  #jogos .jogos-title {
    font-size: 20px !important;
    font-weight: bold;
    vertical-align: top;
    margin-bottom: 10px;
  }
  #jogos .jogos-title .title {
    display: inline-block;
    width: fit-content;
    margin-right: 10px;
    vertical-align: middle;
  }
  #jogos .jogos-title .title h1 {
    font-size: 20px !important;
    margin: 0;
  }
  #jogos .rodada-select {
    display: inline-block;
    width: 150px;
    height: auto;
    vertical-align: middle;
  }
  #jogos .rodada-select .form-select {
    font-size: 20px;
    margin: 0px !important;
    height: auto !important;
  }
  #jogos .jogos-partidas {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 80vh;
    text-align: center;
    align-content: center;
    padding: 10px 10px 0 0;
  }
  #jogos .jogo {
    float: left;
    width: 95%;
    height: auto;
    margin: 10px 0;
  }
  #jogos .jogo-info {
    vertical-align: top;
    width: 0%;
    height: 0px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: #4d4d4d;
    text-align: center;
    align-content: center;
    opacity: 0;
    transform: scaleX(-1);
    transition: all 0.2s ease-in-out;
  }
  #jogos .jogo-aovivo {
    border: 7px solid #00fffd;
    border-radius: 25px;
  }
  #jogos .placar {
    width: 100%;
    height: 70px;
    text-align: center;
    border-bottom: 2px solid white;
  }
  #jogos .mando {
    display: inline-block;
    vertical-align: middle;
    width: 3%;
    height: 100%;
  }
  #jogos .clube {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    width: 15%;
  }
  #jogos .clube img {
    width: 100%;
  }
  #jogos .placar-status {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: auto;
  }
  #jogos .status-titulo,
  #jogos .status-gols,
  #jogos .status-atletas {
    vertical-align: middle;
    color: white;
    font-weight: bold;
    margin: 2px;
  }
  #jogos .status-titulo {
    font-size: 100%;
    padding-bottom: 10px;
  }
  #jogos .status-gols {
    display: inline-block;
    font-size: 180%;
  }
  #jogos .status-atletas {
    display: inline-block;
    font-size: 100%;
    border: 1px inset white;
    border-radius: 20px;
    padding: 5px 10px;
  }
  #jogos .estatistica {
    vertical-align: top;
    width: 100%;
    padding: 5px;
  }
  #jogos .probabilidade {
    width: 100%;
    margin: 15px 0px;
    font-size: 90%;
  }
  #jogos .probabilidade-titulo {
    display: inline-block;
    vertical-align: middle;
    width: 16%;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  #jogos .probabilidade-barra {
    display: inline-block;
    vertical-align: middle;
    width: 82%;
  }
  #jogos .probabilidade-casa,
  #jogos .probabilidade-fora {
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
    color: white;
    border-radius: 20px;
  }
  #jogos .probabilidade-casa {
    padding-left: 10px;
    text-align: left;
  }
  #jogos .probabilidade-fora {
    padding-right: 10px;
    text-align: right;
  }
  #jogos .scouts {
    width: 100%;
    margin: 3px 0px;
    font-size: 80%;
  }
  #jogos .scout-titulo {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  #jogos .scout-barra {
    display: inline-block;
    vertical-align: middle;
    width: 76%;
    margin-left: 8px;
  }
  #jogos .scout-casa,
  #jogos .scout-fora {
    height: 15px;
    overflow: hidden;
    color: white;
    padding: 2px 2px 2px 0px;
    text-align: right;
    margin: 2px;
    border-radius: 20px;
  }
  #jogos .atletas {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-size: 76%;
    width: 50%;
  }
  #jogos .atletas-casa {
    text-align: left;
    padding: 5px;
  }
  #jogos .atletas-fora {
    text-align: right;
    padding: 5px;
  }
  #jogos .atletas-scouts-positivos {
    display: inline-block;
    color: lightgreen;
  }
  #jogos .atletas-scouts-negativos {
    display: inline-block;
    color: lightcoral;
  }
  #jogos .isClienteAtleta {
    border: 2px solid #00fffd;
    border-radius: 10px;
  }
  #jogos .isActive {
    width: 100%;
    height: auto;
    opacity: 1;
    transform: scaleX(1);
  }

  #jogos .btnToogle {
    position: relative;
    top: -10px;
    right: -10px;
  }
  #jogos .icon {
    font-size: 20px !important;
    border: 2px solid white !important;
    padding: 7px !important;
  }

  #jogos .info-adicional {
    width: auto;
    color: white;
    font-size: 100%;
    padding: 3px;
    border-radius: 20px 20px 0px 0px;
    border: 2px solid white;
    min-height: 100px;
  }
  #jogos .info-adicional .local_arbitro {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    width: calc(75% - 6px);
    font-size: 1em;
    margin-right: 10px;
  }
  #jogos .info-adicional .clima_tempo {
    position: absolute;
    top: 20px;
    right: 0px;
    display: inline-block;
    vertical-align: middle;
    width: calc(25% - 6px);
    font-size: 1em;
    font-weight: bold;
  }

  #jogos .info-adicional .clima_tempo i {
    font-size: 3em !important;
  }
}
@media (min-width: 1025px) {
  #jogos {
    height: auto;
    width: 100%;
  }
  #jogos .jogos-title {
    font-size: 30px !important;
    font-weight: bold;
    vertical-align: top;
    height: fit-content;
  }
  #jogos .jogos-title .title {
    display: inline-block;
    width: fit-content;
    margin-right: 10px;
    vertical-align: middle;
  }
  #jogos .jogos-title .title h1 {
    font-size: 40px !important;
    margin: 0;
  }
  #jogos .rodada-select {
    display: inline-block;
    width: 150px;
    height: auto;
    vertical-align: middle;
  }
  #jogos .rodada-select .form-select {
    font-size: 30px;
    margin: 0px !important;
    height: auto !important;
  }
  #jogos .jogos-partidas {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 75vh;
    text-align: center;
    align-content: center;
    padding-top: 20px;
    padding-right: 10px;
  }
  #jogos .jogo {
    display: inline-block;
    vertical-align: top;
    margin: 10px;
    width: calc(25% - 20px);
    height: auto;
  }
  #jogos .jogo-info {
    vertical-align: top;
    width: 0%;
    height: 0px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: #4d4d4d;
    text-align: center;
    align-content: center;
    opacity: 0;
    transform: scaleX(-1);
    transition: all 0.2s ease-in-out;
  }
  #jogos .jogo-aovivo {
    border: 7px solid #00fffd;
    border-radius: 25px;
  }
  #jogos .placar {
    width: auto;
    height: 80px;
    text-align: center;
    border-bottom: 2px solid white;
  }
  #jogos .mando {
    display: inline-block;
    vertical-align: middle;
    width: 3%;
    height: 100%;
    top: -10px;
  }
  #jogos .clube {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    width: 15%;
  }
  #jogos .clube img {
    width: 100%;
  }
  #jogos .placar-status {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: auto;
  }
  #jogos .status-titulo,
  #jogos .status-gols,
  #jogos .status-atletas {
    vertical-align: middle;
    color: white;
    font-weight: bold;
    margin: 2px;
  }
  #jogos .status-titulo {
    font-size: 100%;
    padding-bottom: 10px;
  }
  #jogos .status-gols {
    display: inline-block;
    font-size: 180%;
  }
  #jogos .status-atletas {
    display: inline-block;
    font-size: 100%;
    border: 1px inset white;
    border-radius: 20px;
    padding: 5px 10px;
  }
  #jogos .estatistica {
    vertical-align: top;
    width: 100%;
    padding: 5px;
  }
  #jogos .probabilidade {
    width: 100%;
    margin: 15px 0px;
    font-size: 90%;
  }
  #jogos .probabilidade-titulo {
    display: inline-block;
    vertical-align: middle;
    width: 16%;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  #jogos .probabilidade-barra {
    display: inline-block;
    vertical-align: middle;
    width: 82%;
  }
  #jogos .probabilidade-casa,
  #jogos .probabilidade-fora {
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    line-height: 35px;
    color: white;
    overflow: hidden;
    border-radius: 20px;
    transition: 0.2s ease-in-out;
  }
  #jogos .probabilidade-casa {
    padding-left: 10px;
    text-align: left;
  }
  #jogos .probabilidade-fora {
    padding-right: 10px;
    text-align: right;
  }
  #jogos .scouts {
    width: 100%;
    margin: 3px 0px;
    font-size: 80%;
  }
  #jogos .scout-titulo {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  #jogos .scout-barra {
    display: inline-block;
    vertical-align: middle;
    width: 76%;
    margin-left: 8px;
  }
  #jogos .scout-casa,
  #jogos .scout-fora {
    color: white;
    height: 15px;
    overflow: hidden;
    padding: 2px 2px 2px 0px;
    text-align: right;
    margin: 2px;
    border-radius: 20px;
  }
  #jogos .atletas {
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
    font-size: 76%;
    width: 50%;
  }
  #jogos .atletas-casa {
    text-align: left;
    padding: 5px;
  }
  #jogos .atletas-fora {
    text-align: right;
    padding: 5px;
  }
  #jogos .atletas-scouts-positivos {
    display: inline-block;
    color: lightgreen;
  }
  #jogos .atletas-scouts-negativos {
    display: inline-block;
    color: lightcoral;
  }
  #jogos .isClienteAtleta {
    border: 2px solid #00fffd;
    border-radius: 10px;
  }
  #jogos .isActive {
    width: 100%;
    height: auto;
    opacity: 1;
    transform: scaleX(1);
  }

  #jogos .btnToogle {
    position: relative;
    top: -10px;
    right: -10px;
  }
  #jogos .icon {
    font-size: 20px !important;
    border: 2px solid white !important;
    padding: 7px !important;
  }

  #jogos .info-adicional {
    width: auto;
    color: white;
    font-size: 100%;
    padding: 3px;
    border-radius: 20px 20px 0px 0px;
    border: 2px solid white;
    min-height: 100px;
  }
  #jogos .info-adicional .local_arbitro {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    width: calc(75% - 6px);
    font-size: 1em;
    margin-right: 10px;
  }
  #jogos .info-adicional .clima_tempo {
    position: absolute;
    top: 20px;
    right: 0px;
    display: inline-block;
    vertical-align: middle;
    width: calc(25% - 6px);
    font-size: 1em;
    font-weight: bold;
  }
  #jogos .info-adicional .clima_tempo i {
    font-size: 3em !important;
  }
}
</style>
